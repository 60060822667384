import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils/breakpoints';
import * as btn from 'modules/app/styles/Button.styles';

export const root = css`
  padding: ${spacing(120)} 0;
  background: hsl(var(--color-text-1));
  color: hsl(var(--color-white-11));
  height: 100%;
`;

export const mainContent = css`
  height: auto;
`;

export const customPlan = css`
  width: 100%;
  max-width: ${spacing(584)};
  margin-top: ${spacing(80)};
  height: auto;
`;

export const customPlanContent = css`
  margin-top: ${spacing(40)};

  @media ${breakpoints.medium} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    p {
      flex: 1;
      color: hsl(var(--color-grayscale-4));
    }
  }
`;

export const customPlanBtn = css`
  ${btn.root};
  ${btn.secondary};
  ${btn.small};
  margin-top: ${spacing(20)};

  @media ${breakpoints.medium} {
    margin: 0 0 0 ${spacing(20)};
  }
`;

export const skeleton = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  div {
    padding-top: 87%;
  }

  @media ${breakpoints.medium} {
    column-gap: ${spacing(32)};
  }
`;

export const freeTrial = css`
  margin: ${spacing(60)} 0 ${spacing(60)};
  width: 100%;
  max-width: 584px;
`;

export const freeTrialContent = css`
  margin: ${spacing(40)} 0 ${spacing(32)};
  color: hsl(var(--color-grayscale-4));

  a {
    font-weight: 700;
    text-decoration: underline;
    color: hsl(var(--color-white-11));
  }
`;
